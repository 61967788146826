import React from "react";
import SEO from "../components/seo";
import Main from "../components/main";

const IndexPage = () => (
  <>
    <SEO title="Home Page" keywords={[`qi`, `qichen`]} />
    <Main title={"Hello"} claim={true} hello />
  </>
);

export default IndexPage;
